.PlanCard {
    margin-bottom: 30px;
    width: 330px;
    height: auto;
    padding: 50px 25px;
    border-radius: 10px;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.03);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.f-ar {
    font-size: 3.25rem !important;
}

.plan-title {
    color: #a6a9ab;
}

.PlanCard .plan-price {
    font-size: 4rem;
}

.plan-price,
.tax,
.plan-period {
    font-family: 'Changa', sans-serif !important;
}

.plan-period {
    width: 55px !important;
    top: 85px !important;
}

.PlanCard .features-list {
    list-style-type: none;
    padding-bottom: 20px;
    padding-top: 20px;
}

.PlanCard .features-list li {
    font-size: 14px;
}

.plan-feature {
    margin: 10px 0px;
    font-size: 0.8rem;
}

.cards button {
    background-color: var(--text);
}

.PlanCard button {
    width: 150px;
    height: 30px;
    outline: none;
    border: none;
    color: var(--orange);
    font-weight: bold;
    border-radius: 5px;
    margin-top: auto;
}

.plan-alert {
    color: red;
    font-weight: bold;
    margin-bottom: 25px;
    font-size: 13px;
}

.webprice {
    font-weight: bold;
}

.scale-in-center {
    -webkit-animation: scale-in-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite alternate-reverse both;
    animation: scale-in-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite alternate-reverse both;
}

.sub-btn {
    color: white !important;
}

.sub-btn:hover {
    cursor: pointer;
}

.get-offer {
    position: absolute;
    bottom: 10px;
    font-size: 0.75rem;
    color: var(--orange);
    cursor: pointer;
    text-decoration: none;
}

.custom-plans {
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    top: 0;
}

.active {
    /* background-color: #BF6015 !important; */
    opacity: 1 !important;
    /* transition: all .2s ease-in; */
    color: white !important;
    font-weight: bold;
}

.custom-plan.s {
    border-radius: 0px 0px 0px 10px;
}

.custom-plan.w {
    border-radius: 0px 0px 10px 0px;
}

.custom-plan {
    color: rgba(255, 255, 255, 0.724);
    padding: 5px 8px;
    opacity: 0.70;
    background-color: var(--orange);
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.fz07rem {
    font-size: 0.72rem !important;
}

.tax {
    font-size: 10px;
    position: absolute;
    margin-top: 90px;
    left: 30px;
    color: Red;
}

@media screen and (max-width: 700px) {

    .custom-plan {
        padding: 2.5px 5px;
        font-size: 0.95rem;
    }
}

@media screen and (min-width: 1250px) {
    .PlanCard {
        width: 360px;
    }
}


@-webkit-keyframes scale-in-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 9
    }

    100% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 1;
    }
}

@keyframes scale-in-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 9
    }

    100% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 1;
    }
}