.Contact{
    flex-direction: column;
    position: relative;
    background-size: cover;
    background-position: right;
    margin-bottom: 0px;
}
.form-container{
width: 100%;
z-index: 2;
display: flex;
}
.map,
.Contact h2,
.Contact p,
.social-icons,
.Contact svg.sms{
    z-index: 2;
    margin-bottom: 15px;
}
.social-icons .icon{
width: 50px;
height: 50px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
margin: 0 5px;
}
.social-icons a{
    text-decoration: none;
    color: #fff;
}
.map{
    height: 450px;
    width: 100%;
}


  @media screen and (max-width: 700px) {
    .form-container{
        flex-direction: column;
    }
    .map{
        height: 200px;
        width: 100%;
        margin: 0 !important;
    }
  }