.rec.rec-arrow:hover,.rec.rec-arrow:active,.rec.rec-arrow::after,.rec.rec-arrow:enabled {
  background-color: "transparent" !important;
  box-shadow: none !important;
  color: var(--orange) !important;
}
.krmNah{
  box-shadow: 0 0 1px 3px rgb(224, 125, 27) !important;
  background-color: rgba(239, 166, 22, 0.5) !important;
}

.rec.rec-arrow{
background-color: transparent;
box-shadow: none;
}