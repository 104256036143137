.Footer {
    display: grid;
    height: 350px;
    text-align: center;
    padding-top: 50px;
    grid-template-areas: "a b c d"
        "footer-bottom footer-bottom footer-bottom footer-bottom";
}

.Footer a {
    text-decoration: none;
    color: #fff;
}

.Footer a:hover {
    color: var(--orange);
}
#root > div > div > div.Footer.cwhite > div.footer-col4.col > div > ul > li > a{
    display: flex;
    justify-content: center;
}
.footer-bottom {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 100%;
    padding: 20px;
    font-size: 0.9rem;
    grid-area: footer-bottom;
}

.footer-col4 ul {
    list-style-type: none;
}
.footer-col2{
    grid-area: d;
}
.footer-col1{
    grid-area: a;
}
.footer-col1 img {
    width: 80px;
    margin-bottom: 25px;
}
.Footer .col{
    margin-bottom: 20px;
}
.footer-bottom span {
    color: var(--orange);
    margin: 0px 5px;
}

.Footer svg {
    margin-bottom: 10px;
    width: 50px;
    border-radius: 50px;
    padding: 2px;
}
.Footer .row-icon{
background-color: #fff;
}

.Footer .col .span {
    margin-bottom: 25px;
}

@media screen and (max-width: 700px) {
    .Footer {
        grid-template-areas:
            "a"
            "b"
            "c"
            "d"
            "footer-bottom ";
            height: auto;
    }
    .footer-col3{
display: none;
    }


}