.accessibility-btn{
    position: fixed;
    top: 0;
    right: 0;
    border: none;
    outline: none;
    margin: 200px 0px 0px 0px;
    width: 50px;
    height: 50px;
    color: #fff;
    background-color: #4054B2;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10 !important;
    padding-top: 4px;
    padding-right: 1px;
    transition: all ease-in .3s;
    cursor: pointer;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
}
.accessibility-options {
    position: relative;
  }
  
  .accessibility-menu {
    position: fixed;
    z-index: 99;
    margin-top: 250px;
    top: 0;
    right: -150px;
    width: 150px;
    height: 280px;
    display: flex;
    background-color: #fff;
    flex-direction: column;
    box-shadow: -4px 5px 8px rgba(0, 0, 0, 0.05);
    transition: right 0.3s ease-out;
  }
  .accessibility-menu button{
    cursor: pointer;
    outline: none;
    border: none;
    border-bottom: solid 1px #fff;
    height: 40px;
    background-color: #fff;
    color: #4054B2;
    font-size: 1rem;
  }
  .accessibility-menu button:hover{
    background-color: #4054B2;
    color: #fff;
  }
  .accessibility-menu.visible {
    right: 0;
  }