.Navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
}
.scroll-up{
color: var(--orange);
z-index: 100;
position: fixed;
bottom: 30px;
right: 30px;
display: flex;
justify-content: center;
align-items: center;
opacity: 0.7;
transform: scale(0.8);
transition: all .3s ease;
}
.scroll-up:hover{
    opacity: 1;
    transform: scale(1);
    transition: all .3s ease-in;
    cursor: pointer;
}
.logo {
    width: 115px;
}
.Navbar ul{
    list-style-type: none;
}
.Navbar ul li a {
    text-decoration: none;
    font-weight: 500;
    font-size: 0.9rem;
}
.Navbar ul li a:hover{
    background: var(--qnw);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.links a svg{
fill: var(--orange);
}
.links ul {
    display: flex;
    direction: rtl;
    justify-content: space-evenly;
}
.links ul li {
    padding: 7px;
}
.links a{
    display: flex;
    align-items: center;
    gap: 5px;
}

.mobile-nav {
    display: none;
}

@media screen and (max-width:900px) {
    .links {
        display: none;
    }
    .logo-wrapper{
        margin-right: 30px;
    }
    .Navbar{
        position: fixed;
        width: 100vw;
        z-index: 100;
        box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.23);
    }
    .mobile-links {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 90vw;
        box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.15);
        padding: 35px 50px;
        border-radius: 10px;
        transition: all .3s ease-out;
    }
    .mobile-links li {
        margin: 20px 0px;
    }
    .mobile-links a svg{
        fill: var(--orange);
    }
    .mobile-links a {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 5px;
    }
    .mobile-nav {
        display: flex;
        z-index: 50;
    }
}

/* animista */
.fade-in-top {
    visibility: visible;
    -webkit-animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-in-bottom {
    -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    visibility: hidden;
}

@-webkit-keyframes fade-in-top {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-in-top {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes fade-in-bottom {
    0% {
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-in-bottom {
    0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
    }
}