.ToolCard {
    margin-bottom: 30px;
    width: 350px;
    height: 250px;
    padding: 50px 25px;
    border-radius: 10px;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.03);
}

.ToolCard:hover h2,
.ToolCard:hover p {
    transition: all ease-in .3s
}

.ToolCard h2,
.ToolCard p {
    margin: 10px 0px;
}
.tc-ar{
    margin: 0px 0px !important;
}

.toolIcon {
    color: var(--orange);
}

.Tools .cards>div:first-child,
.Tools .cards>div:first-child h2,
.Tools .cards>div:first-child p,
.Tools .cards>div:first-child .toolIcon {
    background-color: var(--text);
    color: #fff;
}


@media screen and (min-width: 700px) {

    .ToolCard {
        width: 27vmin;
        height: 30vmin;
        margin: 10px;
    }
    .ToolCard h2 {font-size: 1.1rem;}
    .ToolCard p {font-size: 0.8rem;}
}

@media screen and (min-width: 1250px) {
    .ToolCard {
        width: 30vmin;
        height: 30vmin;
    }
    .ToolCard h2 {font-size: 1.5rem;}
    .ToolCard p {font-size: 1rem;}
}