.HeroCard {
    width: 380px;
    height: 200px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    position: relative;
    bottom: 50px;
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.23);
}
.Cards{
flex-direction: column;
}
.HeroCard h2{
    margin-bottom: 10px;
    font-size: 1.6rem;
}
.HeroCard p{
    font-size: 1.1rem;
}
.num{
    position: absolute;
    font-size: 8rem;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    right: 25px;
    opacity: 0.05;
}

  @media screen and (max-width: 700px) {
    .HeroCard{
        width: 90vmin;
        padding: 50px;
    }
    .HeroCard h2{
        font-size: 1.4rem;
    }
    .HeroCard p{
        font-size: 1rem;
    }
  }

  @media screen and (min-width:701px)and (max-width:900px) {
    .HeroCard h2{
        font-size: 1.3rem;
    }
    .HeroCard p{
        font-size: 0.9rem;
    }
}