.Services {
    flex-direction: column;
    overflow-x: hidden;
    position: relative;
}

.image-and-features {
    width: 100%;
}

.Services .igimg {
    width: 90%;
    margin-bottom: 80px;
}

.Services .feature {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: var(--orange);
    margin-bottom: 60px;
}

.features {
    top: 225px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.st-ar{
font-size: 21px !important;
}
.s-ar{
    padding: 5px 10px !important;
}


@keyframes clip1 {
    25% {
        max-width: 0px;
    }

    75% {
        max-width: 200px;
        content: "לקוחות";
    }

    100% {
        max-width: 0px;
        content: "מכירות";
    }

}
@media screen and (min-width: 750px) {
    .Services .igimg {
        width: 60%;
    }
    .features {
        margin-right: 15%;
    }
}

@media screen and (min-width: 1000px) {
    .Services .igimg {
        width: 400px;
    }

    .Services .ServiceCard {
        margin: 12px;
    }

    .Services {
        display: grid;
        grid-template-areas:
            ' SectionHeading SectionHeading sectionTitle sectionTitle'
            ' cards cards image-and-features image-and-features';
    }

    .sectionTitle {
        grid-area: sectionTitle;
    }

    .SectionHeading {
        grid-area: SectionHeading;
    }

    .image-and-features {
        grid-area: image-and-features;
        display: flex;
        justify-content: space-around;
    }

    .cards {
        grid-area: cards;
        display: flex;
        width: 450px;
        margin: 50px;
        height: 450px;
        flex-wrap: wrap;
    }

    .features {
        margin-left: 35%;
    }
}

@media screen and (min-width: 1350px) {
    .Services .igimg {
        width: 500px;
    }
    .cards {
        grid-area: cards;
        display: flex;
        width: 550px;
        margin: 50px;
        height: 550px;
        flex-wrap: wrap;
    }

    .features {
        margin-left: 35%;
    }
}

  .slider-wrapper {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .slider{
    height: 50px;
    padding-left:15px;
    overflow: hidden;
  }
  .slider div {
    color:#fff;
    height: 50px;
    margin-bottom: 50px;
    padding: 10px 10px;
    text-align: center;
    border-radius: 15px;
    box-sizing: border-box;
  }
  .slider-text1 {
    background: #00a8a3;
    animation: slide 5s linear infinite;
  }
  .slider-text2 {
    background: #8795E6;
  }
  .slider-text3 {
    background: #F47A1B;
  }
  @keyframes slide {
    0% {margin-top:-300px;}
    5% {margin-top:-200px;}
    33% {margin-top:-200px;}
    38% {margin-top:-100px;}
    66% {margin-top:-100px;}
    71% {margin-top:0px;}
    100% {margin-top:0px;}
  }