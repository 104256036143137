.ServiceCard{
margin-bottom: 100px;
}
.ServiceCard h2,.ServiceCard img{
    margin-bottom: 10px;
    font-size: 1.2rem;
}
.Services .ServiceCard img{
    width: 50px;
}
.serviceIcon{
    color: var(--orange);
}

@media screen and (min-width: 1000px) {
    .ServiceCard{
        width: 200px;
        font-size: 0.9rem;
    }   
}

@media screen and (min-width: 1350px) {
    .ServiceCard{
        width: 250px;
    }   
}