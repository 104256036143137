@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Changa:wght@300;400;500;700&display=swap');

.he {
    font-family: 'Heebo', sans-serif !important;
}

.ar {
    font-family: 'Changa', sans-serif !important;
}

.App {
    font-family: 'Heebo', sans-serif;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

:root {
    --dark: #303030;
    --light-gray: #FDFBFD;
    --qnw: linear-gradient(308.04deg, #EE6C02 15.8%, #EB9700 88.03%);
    --text: #2F3192;
    --orange: #F47A1A;
    --bluish: #8895E6;
    --greenish: #67D9C8;
}

.btnGradient {
    background: var(--qnw);
    border: none;
    outline: none;
    width: 120px;
    height: 40px;
    border-radius: 8px;

}

.cwhite {
    color: #fff;
}

.bgc-white {
    background-color: #fff;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.bg-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.section {
    padding: 30px;
    margin-bottom: 50px;
}

.sectionTitle {
    margin-bottom: 50px;
}

.gradientText{
    background: var(--qnw);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}