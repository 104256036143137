
.ThemeSwitcher {
  position: absolute;
  margin-left: 130px;
  top: 22px;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 800px) {
  .ThemeSwitcher{
    margin-left: 0;
    top: 0;
    position: relative;
  }
}


.ThemeSwitcher-label {
  position: relative;
  display: block;
  width: 60px;
  height: 30px;
  border-radius: 100px;
  background-color: var(--text);
  overflow: hidden;
  cursor: pointer;
}
.ThemeSwitcher-label:before, .ThemeSwitcher-label:after {
  display: block;
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 5px;
  left: 8px;
  transition: 0.5s ease;
}
.ThemeSwitcher-label:before {
  background-color: #F28C38;
}
.ThemeSwitcher-label:after {
  background-color: var(--text);
  left: -58px;
  transform: scale(0.00001);
}

.ThemeSwitcher-input[type=checkbox] {
  display: none;
}
.ThemeSwitcher-input[type=checkbox]:checked + .ThemeSwitcher-label:before {
  background-color: #fbf422;
  transform: translateX(25px);
}
.ThemeSwitcher-input[type=checkbox]:checked + .ThemeSwitcher-label:after {
  transform: translateX(85px) scale(1);
}