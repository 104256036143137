::-webkit-scrollbar {
    display: none;
}

.IndividualServices, .slider-container{
    margin-top: 30px;
}

.slider-container {
    height: 230px;
    width: 100vw;
    overflow-x: auto;
    white-space: nowrap;
    padding-top: 40px;
    position: relative;
    /* border-bottom: solid 2px var(--orange); */
    padding-left: 30px;
  }
  
  .square {
    height: 150px;
    width: 220px;
    margin-right: 30px;
    display: inline-block;
    border-radius: 10px;
    background-size: cover;
    cursor: pointer;
  }
  .square-content{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .square-overlay{
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .square-title{
    font-size: 1.7rem;
    color: white !important;
    z-index: 2;
  }
  .square-price{
    font-size: 1.3rem;
    font-weight: bold;
    z-index: 2;
  }