.Plans{
    flex-direction: column;
}

.plan-period{
    position: absolute;
    font-size: 0.8rem;
    top: 65px;
    right: 10px;
    background-color: var(--orange);
    color: #fff;
    width: 50px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shekel{
    font-size: 1.5rem;
    position: relative;
    bottom: 20px;
}
.custom-plans{
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    top: 0;
}
.active{
    /* background-color: #BF6015 !important; */
    opacity: 1 !important;
    /* transition: all .2s ease-in; */
    color: white !important;
    font-weight: bold;
}

.custom-plan.s{
border-radius: 0px 0px 0px 10px;
}
.custom-plan.w{
    border-radius: 0px 0px 10px 0px;
}
.custom-plan{
    color: rgba(255, 255, 255, 0.724);
    padding: 5px 8px;
    opacity: 0.70;
    background-color: var(--orange);
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1);
    cursor: pointer;
}
.anchor{
    background-color: transparent;
    height: 70px;
    width: 100vw;
}
@media screen and (min-width: 900px) {

    .Plans .cards{
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        width: 100%;
        height: auto;
    }
}
@media screen and (max-width: 700px) {

    .custom-plan{
        padding: 2.5px 5px;
        font-size: 0.95rem;
    }
}