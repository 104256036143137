@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Changa:wght@300;400;500;700&display=swap);
.he {
    font-family: 'Heebo', sans-serif !important;
}

.ar {
    font-family: 'Changa', sans-serif !important;
}

.App {
    font-family: 'Heebo', sans-serif;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

:root {
    --dark: #303030;
    --light-gray: #FDFBFD;
    --qnw: linear-gradient(308.04deg, #EE6C02 15.8%, #EB9700 88.03%);
    --text: #2F3192;
    --orange: #F47A1A;
    --bluish: #8895E6;
    --greenish: #67D9C8;
}

.btnGradient {
    background: var(--qnw);
    border: none;
    outline: none;
    width: 120px;
    height: 40px;
    border-radius: 8px;

}

.cwhite {
    color: #fff;
}

.bgc-white {
    background-color: #fff;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.bg-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.section {
    padding: 30px;
    margin-bottom: 50px;
}

.sectionTitle {
    margin-bottom: 50px;
}

.gradientText{
    background: var(--qnw);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.avatar {
	width: 80px;
	height: 80px;
	box-sizing: border-box;
	border-radius: 50%;
	overflow: hidden;
	box-shadow: 0 2.5px 15px 0px rgba(0,0,0,0.25);
	transform: translatey(0px);
	animation: float 3.5s ease-in-out infinite;
    position: relative;
}
  
.ClientFavicon:nth-child(odd) .avatar {
	animation: vibrate-1 2.5s linear infinite both;
}
.ClientFavicon:nth-child(even) .avatar {
	animation: vibrate-2 2.5s linear infinite both;
}
.avatar img { width: 100%; height: auto; object-fit: cover;}

.content {
	width: 100%;
	max-width: 600px;
	padding: 20px 40px;
	box-sizing: border-box;
	text-align: center;
}


/* ----------------------------------------------
 * Generated by Animista on 2022-7-9 15:36:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
  @keyframes vibrate-1 {
	0% {
			transform: translate(0);
	}
	20% {
			transform: translate(-3px, 3px);
	}
	40% {
			transform: translate(-3px, -3px);
	}
	60% {
			transform: translate(3px, 3px);
	}
	80% {
			transform: translate(3px, -3px);
	}
	100% {
			transform: translate(0);
	}
  }
  
  /* ----------------------------------------------
 * Generated by Animista on 2022-7-9 15:38:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-2
 * ----------------------------------------
 */
  @keyframes vibrate-2 {
	0% {
			transform: translate(0);
	}
	20% {
			transform: translate(3px, -3px);
	}
	40% {
			transform: translate(3px, 3px);
	}
	60% {
			transform: translate(-3px, 3px);
	}
	80% {
			transform: translate(-3px, -3px);
	}
	100% {
			transform: translate(0);
	}
  }
  /* ----------------------------------------------
 * Generated by Animista on 2022-7-9 15:36:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
  @keyframes vibrate-1 {
	0% {
			transform: translate(0);
	}
	20% {
			transform: translate(-3px, 3px);
	}
	40% {
			transform: translate(-3px, -3px);
	}
	60% {
			transform: translate(3px, 3px);
	}
	80% {
			transform: translate(3px, -3px);
	}
	100% {
			transform: translate(0);
	}
  }
.HeroCard {
    width: 380px;
    height: 200px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    position: relative;
    bottom: 50px;
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.23);
}
.Cards{
flex-direction: column;
}
.HeroCard h2{
    margin-bottom: 10px;
    font-size: 1.6rem;
}
.HeroCard p{
    font-size: 1.1rem;
}
.num{
    position: absolute;
    font-size: 8rem;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    right: 25px;
    opacity: 0.05;
}

  @media screen and (max-width: 700px) {
    .HeroCard{
        width: 90vmin;
        padding: 50px;
    }
    .HeroCard h2{
        font-size: 1.4rem;
    }
    .HeroCard p{
        font-size: 1rem;
    }
  }

  @media screen and (min-width:701px)and (max-width:900px) {
    .HeroCard h2{
        font-size: 1.3rem;
    }
    .HeroCard p{
        font-size: 0.9rem;
    }
}

.Navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
}
.scroll-up{
color: var(--orange);
z-index: 100;
position: fixed;
bottom: 30px;
right: 30px;
display: flex;
justify-content: center;
align-items: center;
opacity: 0.7;
transform: scale(0.8);
transition: all .3s ease;
}
.scroll-up:hover{
    opacity: 1;
    transform: scale(1);
    transition: all .3s ease-in;
    cursor: pointer;
}
.logo {
    width: 115px;
}
.Navbar ul{
    list-style-type: none;
}
.Navbar ul li a {
    text-decoration: none;
    font-weight: 500;
    font-size: 0.9rem;
}
.Navbar ul li a:hover{
    background: var(--qnw);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.links a svg{
fill: var(--orange);
}
.links ul {
    display: flex;
    direction: rtl;
    justify-content: space-evenly;
}
.links ul li {
    padding: 7px;
}
.links a{
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.mobile-nav {
    display: none;
}

@media screen and (max-width:900px) {
    .links {
        display: none;
    }
    .logo-wrapper{
        margin-right: 30px;
    }
    .Navbar{
        position: fixed;
        width: 100vw;
        z-index: 100;
        box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.23);
    }
    .mobile-links {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 90vw;
        box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.15);
        padding: 35px 50px;
        border-radius: 10px;
        transition: all .3s ease-out;
    }
    .mobile-links li {
        margin: 20px 0px;
    }
    .mobile-links a svg{
        fill: var(--orange);
    }
    .mobile-links a {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        grid-gap: 5px;
        gap: 5px;
    }
    .mobile-nav {
        display: flex;
        z-index: 50;
    }
}

/* animista */
.fade-in-top {
    visibility: visible;
    animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-in-bottom {
    animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    visibility: hidden;
}

@keyframes fade-in-top {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-in-bottom {
    0% {
        transform: translateY(0px);
        opacity: 1;
    }

    100% {
        transform: translateY(-50px);
        opacity: 0;
    }
}

.ThemeSwitcher {
  position: absolute;
  margin-left: 130px;
  top: 22px;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 800px) {
  .ThemeSwitcher{
    margin-left: 0;
    top: 0;
    position: relative;
  }
}


.ThemeSwitcher-label {
  position: relative;
  display: block;
  width: 60px;
  height: 30px;
  border-radius: 100px;
  background-color: var(--text);
  overflow: hidden;
  cursor: pointer;
}
.ThemeSwitcher-label:before, .ThemeSwitcher-label:after {
  display: block;
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 5px;
  left: 8px;
  transition: 0.5s ease;
}
.ThemeSwitcher-label:before {
  background-color: #F28C38;
}
.ThemeSwitcher-label:after {
  background-color: var(--text);
  left: -58px;
  transform: scale(0.00001);
}

.ThemeSwitcher-input[type=checkbox] {
  display: none;
}
.ThemeSwitcher-input[type=checkbox]:checked + .ThemeSwitcher-label:before {
  background-color: #fbf422;
  transform: translateX(25px);
}
.ThemeSwitcher-input[type=checkbox]:checked + .ThemeSwitcher-label:after {
  transform: translateX(85px) scale(1);
}
.PlanCard {
    margin-bottom: 30px;
    width: 330px;
    height: auto;
    padding: 50px 25px;
    border-radius: 10px;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.03);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.f-ar {
    font-size: 3.25rem !important;
}

.plan-title {
    color: #a6a9ab;
}

.PlanCard .plan-price {
    font-size: 4rem;
}

.plan-price,
.tax,
.plan-period {
    font-family: 'Changa', sans-serif !important;
}

.plan-period {
    width: 55px !important;
    top: 85px !important;
}

.PlanCard .features-list {
    list-style-type: none;
    padding-bottom: 20px;
    padding-top: 20px;
}

.PlanCard .features-list li {
    font-size: 14px;
}

.plan-feature {
    margin: 10px 0px;
    font-size: 0.8rem;
}

.cards button {
    background-color: var(--text);
}

.PlanCard button {
    width: 150px;
    height: 30px;
    outline: none;
    border: none;
    color: var(--orange);
    font-weight: bold;
    border-radius: 5px;
    margin-top: auto;
}

.plan-alert {
    color: red;
    font-weight: bold;
    margin-bottom: 25px;
    font-size: 13px;
}

.webprice {
    font-weight: bold;
}

.scale-in-center {
    animation: scale-in-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite alternate-reverse both;
}

.sub-btn {
    color: white !important;
}

.sub-btn:hover {
    cursor: pointer;
}

.get-offer {
    position: absolute;
    bottom: 10px;
    font-size: 0.75rem;
    color: var(--orange);
    cursor: pointer;
    text-decoration: none;
}

.custom-plans {
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    top: 0;
}

.active {
    /* background-color: #BF6015 !important; */
    opacity: 1 !important;
    /* transition: all .2s ease-in; */
    color: white !important;
    font-weight: bold;
}

.custom-plan.s {
    border-radius: 0px 0px 0px 10px;
}

.custom-plan.w {
    border-radius: 0px 0px 10px 0px;
}

.custom-plan {
    color: rgba(255, 255, 255, 0.724);
    padding: 5px 8px;
    opacity: 0.70;
    background-color: var(--orange);
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.fz07rem {
    font-size: 0.72rem !important;
}

.tax {
    font-size: 10px;
    position: absolute;
    margin-top: 90px;
    left: 30px;
    color: Red;
}

@media screen and (max-width: 700px) {

    .custom-plan {
        padding: 2.5px 5px;
        font-size: 0.95rem;
    }
}

@media screen and (min-width: 1250px) {
    .PlanCard {
        width: 360px;
    }
}

@keyframes scale-in-center {
    0% {
        transform: scale(1);
        opacity: 9
    }

    100% {
        transform: scale(1.2);
        opacity: 1;
    }
}
.ServiceCard{
margin-bottom: 100px;
}
.ServiceCard h2,.ServiceCard img{
    margin-bottom: 10px;
    font-size: 1.2rem;
}
.Services .ServiceCard img{
    width: 50px;
}
.serviceIcon{
    color: var(--orange);
}

@media screen and (min-width: 1000px) {
    .ServiceCard{
        width: 200px;
        font-size: 0.9rem;
    }   
}

@media screen and (min-width: 1350px) {
    .ServiceCard{
        width: 250px;
    }   
}
.ToolCard {
    margin-bottom: 30px;
    width: 350px;
    height: 250px;
    padding: 50px 25px;
    border-radius: 10px;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.03);
}

.ToolCard:hover h2,
.ToolCard:hover p {
    transition: all ease-in .3s
}

.ToolCard h2,
.ToolCard p {
    margin: 10px 0px;
}
.tc-ar{
    margin: 0px 0px !important;
}

.toolIcon {
    color: var(--orange);
}

.Tools .cards>div:first-child,
.Tools .cards>div:first-child h2,
.Tools .cards>div:first-child p,
.Tools .cards>div:first-child .toolIcon {
    background-color: var(--text);
    color: #fff;
}


@media screen and (min-width: 700px) {

    .ToolCard {
        width: 27vmin;
        height: 30vmin;
        margin: 10px;
    }
    .ToolCard h2 {font-size: 1.1rem;}
    .ToolCard p {font-size: 0.8rem;}
}

@media screen and (min-width: 1250px) {
    .ToolCard {
        width: 30vmin;
        height: 30vmin;
    }
    .ToolCard h2 {font-size: 1.5rem;}
    .ToolCard p {font-size: 1rem;}
}
.LngSelect{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: absolute;
    background-color: rgba(28, 10, 2, 0.876);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.LngSelect .row{
    display: flex;
    flex-wrap: wrap;
}
.LngSelect img{
    width: 125px;
    position: absolute;
    top: 80px;
}
.LngSelect .social-icons
{
    position: absolute;
    bottom: 80px;
}
.LngSelect .social-icons a
{
    background-color: var(--orange) !important;
    color: white;
}
.LngSelect a {
    text-decoration: none;
    color: #fff;
}
.arabic,.hebrew,.english{
    z-index: 10;
background-color: var(--orange);
width: 100px;
height: 100px;
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
margin: 20px;
font-size: 1.1rem;
}

.arabic:hover,.hebrew:hover,
.arabic:active,.hebrew:active{
    box-shadow: inset 2px 5px 10px 8px rgba(0,0,0,0.23);
}
.contact-form{
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 2;
}
.contact-form input,
input[type="submit"],
.contact-form textarea{
border-radius: 8px;
height: 40px;
margin-bottom: 20px;
outline: none;
border: none;
padding: 0px 10px;
opacity: 0.7;
}
.contact-form input[type="submit"]
{
    opacity: 1;
}
.contact-form label{
margin-bottom: 8px;
}
.contact-form textarea{
    height: auto;
    margin-bottom: 30px;
    padding-top: 10px;
}
.contact-form input[type="submit"]{
height: 40px;
background: linear-gradient(270deg, #6E61E9 0%, #9087F1 98.98%);
}
#bubbles {
  height: 100%;
}

.bubble {
  width: 2.5vw;
  height: 2.5vw;
  border-radius: 10vw;
  -moz-border-radius: 10vw;
  -webkit-border-radius: 10vw;
  position: fixed;
  bottom: 0;
  z-index: 100;
}

.x1 {
  left: 85vw;
  background-color: orange;
  transform: scale(0.3);
  opacity: 0.2;
  animation: moveBubbles 4s linear infinite, sideWays 4s ease-in-out infinite alternate;
}

.x2 {
  left: 5vw;
  background-color: rgb(255, 128, 0);
  transform: scale(0.20);
  opacity: 0.5;
  animation: moveBubbles 6s linear infinite, sideWays 5s ease-in-out infinite alternate;
}

.x3 {
  background-color: rgb(255, 179, 0);
  left: 35vw;
  transform: scale(0.2);
  opacity: 0.3;
  animation: moveBubbles 5s linear infinite, sideWays 4s ease-in-out infinite alternate;
}

.x4 {
  background-color: orange;
  left: 47vw;
  transform: scale(0.18);
  opacity: 0.35;
  animation: moveBubbles 8s linear infinite, sideWays 2s ease-in-out infinite alternate;
}

.x5 {
    background-color: rgb(255, 136, 0);
  left: 15vw;
  transform: scale(0.25);
  opacity: 0.3;
  animation: moveBubbles 5s linear infinite, sideWays 1s ease-in-out infinite alternate;
}

.x6 {
    background-color: rgb(255, 132, 0);
  left: 73vw;
  transform: scale(0.28);
  opacity: 0.2;
  animation: moveBubbles 4s linear infinite, sideWays 4s ease-in-out infinite alternate;
}

.x7 {
    background-color: rgb(255, 128, 0);
  left: 67vw;
  transform: scale(0.10);
  opacity: 0.5;
  animation: moveBubbles 11s linear infinite, sideWays 5s ease-in-out infinite alternate;
}

.x8 {
    background-color: rgb(255, 115, 0);
  left: 56vw;
  transform: scale(0.15);
  opacity: 0.3;
  animation: moveBubbles 9s linear infinite, sideWays 4s ease-in-out infinite alternate;
}

.x9 {
    background-color: orange;
  left: 20vw;
  transform: scale(0.23);
  opacity: 0.35;
  animation: moveBubbles 4.5s linear infinite, sideWays 2s ease-in-out infinite alternate;
}

.x10 {
    background-color: rgb(255, 162, 0);
  left: 33vw;
  transform: scale(0.15);
  opacity: 0.3;
  animation: moveBubbles 11.3s linear infinite, sideWays 5s ease-in-out infinite alternate;
}

@keyframes moveBubbles {
  0% {
    top: 100vh;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 0vh;
    opacity: 0;
  }
}

@keyframes sideWays {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: 5vw;
  }
}
.rec.rec-arrow:hover,.rec.rec-arrow:active,.rec.rec-arrow::after,.rec.rec-arrow:enabled {
  background-color: "transparent" !important;
  box-shadow: none !important;
  color: var(--orange) !important;
}
.krmNah{
  box-shadow: 0 0 1px 3px rgb(224, 125, 27) !important;
  background-color: rgba(239, 166, 22, 0.5) !important;
}

.rec.rec-arrow{
background-color: transparent;
box-shadow: none;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.MonthlyYearlyBtn{
    margin-bottom: 25px;
}

.toggle, .toggler {
  display: inline-block;
  vertical-align: middle;
  margin: 10px;
}

.toggler {
  color: #ddd;
  transition: .2s;
  font-weight: bold;
}

.toggler--is-active {
  color: var(--orange);
}

.b {
  display: block;
}

.toggle {
  position: relative;
  width: 80px;
  height: 35px;
  border-radius: 100px;
  background-color: var(--orange);
  overflow: hidden;
  box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.05);
}

.check {
  position: absolute;
  display: block;
  cursor: pointer;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 6;
}

.check:checked ~ .switch {
  left: 2px;
  right: 57.5%;
  transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: right, left;
  transition-delay: .08s, 0s;
}

.switch {
  position: absolute;
  right: 2px;
  top: 2px;
  bottom: 2px;
  left: 57.5%;
  background-color: #fff;
  border-radius: 36px;
  z-index: 1;
  transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: right, left;
  transition-delay: 0s, .08s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar {
    display: none;
}

.IndividualServices, .slider-container{
    margin-top: 30px;
}

.slider-container {
    height: 230px;
    width: 100vw;
    overflow-x: auto;
    white-space: nowrap;
    padding-top: 40px;
    position: relative;
    /* border-bottom: solid 2px var(--orange); */
    padding-left: 30px;
  }
  
  .square {
    height: 150px;
    width: 220px;
    margin-right: 30px;
    display: inline-block;
    border-radius: 10px;
    background-size: cover;
    cursor: pointer;
  }
  .square-content{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .square-overlay{
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .square-title{
    font-size: 1.7rem;
    color: white !important;
    z-index: 2;
  }
  .square-price{
    font-size: 1.3rem;
    font-weight: bold;
    z-index: 2;
  }
.accessibility-btn{
    position: fixed;
    top: 0;
    right: 0;
    border: none;
    outline: none;
    margin: 200px 0px 0px 0px;
    width: 50px;
    height: 50px;
    color: #fff;
    background-color: #4054B2;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10 !important;
    padding-top: 4px;
    padding-right: 1px;
    transition: all ease-in .3s;
    cursor: pointer;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
}
.accessibility-options {
    position: relative;
  }
  
  .accessibility-menu {
    position: fixed;
    z-index: 99;
    margin-top: 250px;
    top: 0;
    right: -150px;
    width: 150px;
    height: 280px;
    display: flex;
    background-color: #fff;
    flex-direction: column;
    box-shadow: -4px 5px 8px rgba(0, 0, 0, 0.05);
    transition: right 0.3s ease-out;
  }
  .accessibility-menu button{
    cursor: pointer;
    outline: none;
    border: none;
    border-bottom: solid 1px #fff;
    height: 40px;
    background-color: #fff;
    color: #4054B2;
    font-size: 1rem;
  }
  .accessibility-menu button:hover{
    background-color: #4054B2;
    color: #fff;
  }
  .accessibility-menu.visible {
    right: 0;
  }
.SvcForm {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    position: fixed;
    background-color: #323233;
  }
  .question{
    margin-bottom: 30px;
  }
  .row{
    display: flex; 
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 10px;
  }
.radio-option{
  font-size: 0.8rem;
}
.SvcForm input[type="radio"]{
  transform: scale(1.25);
}
.SvcForm form{
    background-color: #1E1E1E;
    color: #fff;
    width: 360px;
    height: 600px;
    border-radius: 15px;
    padding: 25px;
    direction: rtl;
    text-align: right;
    overflow-y: scroll;
}
  
  .SvcForm label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: 0.85rem;
  }
  .SvcForm small{
    font-size: 12.5px;
    font-weight: normal;
    margin-bottom: 4px;
    color: #F47A1B;
  }
  
  .SvcForm input[type="text"],
  textarea,select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    background-color: #f8f9fa;
    color: #495057;
    font-size: 14px;
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
  }

  .SvcForm select.form-select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    background-color: #f8f9fa;
    color: #495057;
    font-size: 14px;
    width: 100%;
  }
  
  .SvcForm button {
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    background-color: #F47A1B;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    margin-top: 20px;
    font-family: 'Changa', sans-serif;
  }
  
  .SvcForm button:hover {
    transition: .3s all ease-in;
    transform: scale(1.1);
    background-color: #ce5900;
  }
  
.Hero{
    height: 600px;
    grid-gap: 20px;
    gap: 20px;
    flex-direction: column;
    background-size: cover;
    background-position: 50%;
    position: relative;
}
.Hero h1,.Hero p,.Hero button{
    color: #fff;
}
.Hero h1{
font-size: 2.5rem;
}
.contactBtn{
cursor: pointer;
    }
.Cards{
    flex-direction: row;
}
@media screen and (max-width:700px) {
.Hero{
    height: 500px;
}
.Cards{
    flex-direction: column;
}
.Hero h1{
    width: 80%;
    font-size: 1.6rem;
}
}
@media screen and (min-width:701px)and (max-width:900px) {
    .Hero h1{
        width: 80%;
        font-size: 2.5rem;
    }
}
.Services {
    flex-direction: column;
    overflow-x: hidden;
    position: relative;
}

.image-and-features {
    width: 100%;
}

.Services .igimg {
    width: 90%;
    margin-bottom: 80px;
}

.Services .feature {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: var(--orange);
    margin-bottom: 60px;
}

.features {
    top: 225px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.st-ar{
font-size: 21px !important;
}
.s-ar{
    padding: 5px 10px !important;
}


@keyframes clip1 {
    25% {
        max-width: 0px;
    }

    75% {
        max-width: 200px;
        content: "לקוחות";
    }

    100% {
        max-width: 0px;
        content: "מכירות";
    }

}
@media screen and (min-width: 750px) {
    .Services .igimg {
        width: 60%;
    }
    .features {
        margin-right: 15%;
    }
}

@media screen and (min-width: 1000px) {
    .Services .igimg {
        width: 400px;
    }

    .Services .ServiceCard {
        margin: 12px;
    }

    .Services {
        display: grid;
        grid-template-areas:
            ' SectionHeading SectionHeading sectionTitle sectionTitle'
            ' cards cards image-and-features image-and-features';
    }

    .sectionTitle {
        grid-area: sectionTitle;
    }

    .SectionHeading {
        grid-area: SectionHeading;
    }

    .image-and-features {
        grid-area: image-and-features;
        display: flex;
        justify-content: space-around;
    }

    .cards {
        grid-area: cards;
        display: flex;
        width: 450px;
        margin: 50px;
        height: 450px;
        flex-wrap: wrap;
    }

    .features {
        margin-left: 35%;
    }
}

@media screen and (min-width: 1350px) {
    .Services .igimg {
        width: 500px;
    }
    .cards {
        grid-area: cards;
        display: flex;
        width: 550px;
        margin: 50px;
        height: 550px;
        flex-wrap: wrap;
    }

    .features {
        margin-left: 35%;
    }
}

  .slider-wrapper {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .slider{
    height: 50px;
    padding-left:15px;
    overflow: hidden;
  }
  .slider div {
    color:#fff;
    height: 50px;
    margin-bottom: 50px;
    padding: 10px 10px;
    text-align: center;
    border-radius: 15px;
    box-sizing: border-box;
  }
  .slider-text1 {
    background: #00a8a3;
    animation: slide 5s linear infinite;
  }
  .slider-text2 {
    background: #8795E6;
  }
  .slider-text3 {
    background: #F47A1B;
  }
  @keyframes slide {
    0% {margin-top:-300px;}
    5% {margin-top:-200px;}
    33% {margin-top:-200px;}
    38% {margin-top:-100px;}
    66% {margin-top:-100px;}
    71% {margin-top:0px;}
    100% {margin-top:0px;}
  }
.WwdCard{
    position: relative;
    height: 270px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    background-color: ORANGE;
}
.WwdCard h1,.WwdCard h4,.icon{
margin: 10px 0px;
z-index: 2;
}
.Whatwedo{
flex-direction: column;
overflow-x: hidden;
}

@media screen and (min-width: 900px) {
    .Whatwedo{
        flex-direction: row;
        padding: 0;
    }
}
.Tools {
    flex-direction: column;
    overflow-x: hidden;
}

@media screen and (min-width: 700px) {

    .tools-cards {
        width: calc((23vmin) * 4);
        display: flex;
        flex-wrap: wrap;
        justify-content: right;
        padding-right: 10px;
    }
}

@media screen and (min-width: 1250px) {
    .tools-cards {
        width: calc((32.5vmin) * 4);
    }
}
.Plans{
    flex-direction: column;
}

.plan-period{
    position: absolute;
    font-size: 0.8rem;
    top: 65px;
    right: 10px;
    background-color: var(--orange);
    color: #fff;
    width: 50px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shekel{
    font-size: 1.5rem;
    position: relative;
    bottom: 20px;
}
.custom-plans{
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    top: 0;
}
.active{
    /* background-color: #BF6015 !important; */
    opacity: 1 !important;
    /* transition: all .2s ease-in; */
    color: white !important;
    font-weight: bold;
}

.custom-plan.s{
border-radius: 0px 0px 0px 10px;
}
.custom-plan.w{
    border-radius: 0px 0px 10px 0px;
}
.custom-plan{
    color: rgba(255, 255, 255, 0.724);
    padding: 5px 8px;
    opacity: 0.70;
    background-color: var(--orange);
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1);
    cursor: pointer;
}
.anchor{
    background-color: transparent;
    height: 70px;
    width: 100vw;
}
@media screen and (min-width: 900px) {

    .Plans .cards{
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        width: 100%;
        height: auto;
    }
}
@media screen and (max-width: 700px) {

    .custom-plan{
        padding: 2.5px 5px;
        font-size: 0.95rem;
    }
}
.favicons {
    width: 100vw;
    position: relative;
    /* background-color: red; */
  }
  
  .ClientFavicon {
    position: absolute;
    transform: translateY(-30%);
  }
  
  
  
.Clients{
flex-direction: column;
overflow-x: hidden;
}
.clients-slider img{
    width: 80%;
}
.clients-slider{
    width: 100vw;
    height: 230px;
    display: flex;
    justify-content: center;
    grid-gap: 50px;
    gap: 50px;
    align-items: center;
}

@media screen and (min-width: 900px) {
    .clients-slider img{
        width: 400px;
    }
    .clients-slider{
        height: 300px;
        margin-top: 50px;
    }
    .favicons{
        display: flex;
        flex-direction: row;
    }
    .favicons .ClientFavicon{
        /* margin: 150px 0px; */

    }
    .favicons .ClientFavicon:nth-child(even){
        transform: translateY(50px);
    }
}
.websites-type{
display: flex;
justify-content: center;
align-items: center;
width: 100%;
grid-gap: 10px;
gap: 10px;
height: 50px;
}
.websites-type button {
    border: none;
    height: 35px;
    
    /* width: 100px; */
    border-radius: 17px;
    opacity: 0.7; /* Initial opacity */
    color: rgba(255, 255, 255, 0.724);
    padding: 5px 15px;
    background-color: var(--orange);
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  /* Style for the active button */
  .websites-type button.active {
    opacity: 1;
  }
.Whorwe{
    flex-direction: column;
    padding: 100px;
}

@media screen and (max-width: 700px) {
    .Whorwe{
        flex-direction: column;
        padding: 25px;
    }   
}
.Contact{
    flex-direction: column;
    position: relative;
    background-size: cover;
    background-position: right;
    margin-bottom: 0px;
}
.form-container{
width: 100%;
z-index: 2;
display: flex;
}
.map,
.Contact h2,
.Contact p,
.social-icons,
.Contact svg.sms{
    z-index: 2;
    margin-bottom: 15px;
}
.social-icons .icon{
width: 50px;
height: 50px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
margin: 0 5px;
}
.social-icons a{
    text-decoration: none;
    color: #fff;
}
.map{
    height: 450px;
    width: 100%;
}


  @media screen and (max-width: 700px) {
    .form-container{
        flex-direction: column;
    }
    .map{
        height: 200px;
        width: 100%;
        margin: 0 !important;
    }
  }
.Footer {
    display: grid;
    height: 350px;
    text-align: center;
    padding-top: 50px;
    grid-template-areas: "a b c d"
        "footer-bottom footer-bottom footer-bottom footer-bottom";
}

.Footer a {
    text-decoration: none;
    color: #fff;
}

.Footer a:hover {
    color: var(--orange);
}
#root > div > div > div.Footer.cwhite > div.footer-col4.col > div > ul > li > a{
    display: flex;
    justify-content: center;
}
.footer-bottom {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 100%;
    padding: 20px;
    font-size: 0.9rem;
    grid-area: footer-bottom;
}

.footer-col4 ul {
    list-style-type: none;
}
.footer-col2{
    grid-area: d;
}
.footer-col1{
    grid-area: a;
}
.footer-col1 img {
    width: 80px;
    margin-bottom: 25px;
}
.Footer .col{
    margin-bottom: 20px;
}
.footer-bottom span {
    color: var(--orange);
    margin: 0px 5px;
}

.Footer svg {
    margin-bottom: 10px;
    width: 50px;
    border-radius: 50px;
    padding: 2px;
}
.Footer .row-icon{
background-color: #fff;
}

.Footer .col .span {
    margin-bottom: 25px;
}

@media screen and (max-width: 700px) {
    .Footer {
        grid-template-areas:
            "a"
            "b"
            "c"
            "d"
            "footer-bottom ";
            height: auto;
    }
    .footer-col3{
display: none;
    }


}
.menux:hover{
  transition: transform ease-out 200ms;
  opacity: 1; 
}
.menux{
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  margin: 75px 20px;
}
.menu-item{
display: flex;
justify-content: center;
align-items: center;
width: 45px;
height: 45px;
}
.menu-open-button {
  background: #EEEEEE;
}
.menu-item,
.menu-open-button {
  position: absolute;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  color: #FFFFFF;
  text-align: center;
  line-height: 80px;
  transform: translate3d(0, 0, 0);
  transition: transform ease-out 200ms;
}
.menu-item{
overflow: hidden;
cursor: pointer;
border: none;
}

.menu-open {
  display: none;
}

.flag-icon{
  height: 60px;
}
.he-lang {
  background-color: #669AE1;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.07);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.he-lang:hover {
  color: #669AE1;
  text-shadow: none;
}

.ar-lang {
  background-color: #ffff;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.07);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.ar-lang:hover {
  background-color: #70CC72;
  text-shadow: none;
}

.instagram {
  color: #FE4365;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.07);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.instagram:hover {
  color: #FE4365;
  text-shadow: none;
}
.lines {
  width: 25px;
  height: 2.5px;
  background: #596778;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -12.5px;
  margin-top: -1.5px;
  transition: transform 200ms;
}

.line-1 {
  transform: translate3d(0, -8px, 0);
}

.line-2 {
  transform: translate3d(0, 0, 0);
}

.line-3 {
  transform: translate3d(0, 8px, 0);
}

.menu-open:checked + .menu-open-button .line-1 {
  transform: translate3d(0, 0, 0) rotate(45deg);
}

.menu-open:checked + .menu-open-button .line-2 {
  transform: translate3d(0, 0, 0) scale(0.1, 1);
}

.menu-open:checked + .menu-open-button .line-3 {
  transform: translate3d(0, 0, 0) rotate(-45deg);
}


/* .menu-item {
  transition: all 0.1s ease 0s;
} */

.menu-item:hover {
  background: #EEEEEE;
  color: #3290B1;
}

.menu-item:nth-child(3) {
  transition-duration: 180ms;
}

.menu-item:nth-child(4) {
  transition-duration: 180ms;
}

.menu-item:nth-child(5) {
  transition-duration: 180ms;
}

.menu-item:nth-child(6) {
  transition-duration: 180ms;
}

.menu-item:nth-child(7) {
  transition-duration: 180ms;
}

.menu-item:nth-child(8) {
  transition-duration: 180ms;
}

.menu-item:nth-child(9) {
  transition-duration: 180ms;
}

.menu-open-button {
  z-index: 2;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 400ms;
  transform: scale(1.1, 1.1) translate3d(0, 0, 0);
  cursor: pointer;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.07);
}

.menu-open-button:hover {
  transform: scale(1.2, 1.2) translate3d(0, 0, 0);
}

.menu-open:checked + .menu-open-button {
  transition-timing-function: linear;
  transition-duration: 200ms;
  transform: scale(0.8, 0.8) translate3d(0, 0, 0);
}

.menu-open:checked ~ .menu-item {
  transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
}

.menu-open:checked ~ .menu-item:nth-child(3) {
  transition-duration: 180ms;
  -webkit-transition-duration: 180ms;
  transform: translate3d(0.08361px, -70.99997px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(4) {
  transition-duration: 280ms;
  -webkit-transition-duration: 280ms;
  transform: translate3d(60.9466px, -52.47586px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(5) {
  transition-duration: 380ms;
  -webkit-transition-duration: 380ms;
  transform: translate3d(60.9466px, 15.47586px, 0);
}

.credit {
  margin: 24px 20px 120px 0;
  color: #EEEEEE;
}

.credit a {
  padding: 8px 0;
  color: #C49CDE;
  text-decoration: none;
  transition: all 0.3s ease 0s;
}

.credit a:hover {
  text-decoration: underline;
}

