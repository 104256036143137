.menux:hover{
  transition: -webkit-transform ease-out 200ms;
  transition: transform ease-out 200ms;
  transition: transform ease-out 200ms, -webkit-transform ease-out 200ms;
  opacity: 1; 
}
.menux{
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  margin: 75px 20px;
}
.menu-item{
display: flex;
justify-content: center;
align-items: center;
width: 45px;
height: 45px;
}
.menu-open-button {
  background: #EEEEEE;
}
.menu-item,
.menu-open-button {
  position: absolute;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  color: #FFFFFF;
  text-align: center;
  line-height: 80px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform ease-out 200ms;
  transition: -webkit-transform ease-out 200ms;
  transition: transform ease-out 200ms;
  transition: transform ease-out 200ms, -webkit-transform ease-out 200ms;
}
.menu-item{
overflow: hidden;
cursor: pointer;
border: none;
}

.menu-open {
  display: none;
}

.flag-icon{
  height: 60px;
}
.he-lang {
  background-color: #669AE1;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.07);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.he-lang:hover {
  color: #669AE1;
  text-shadow: none;
}

.ar-lang {
  background-color: #ffff;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.07);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.ar-lang:hover {
  background-color: #70CC72;
  text-shadow: none;
}

.instagram {
  color: #FE4365;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.07);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.instagram:hover {
  color: #FE4365;
  text-shadow: none;
}
.lines {
  width: 25px;
  height: 2.5px;
  background: #596778;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -12.5px;
  margin-top: -1.5px;
  -webkit-transition: -webkit-transform 200ms;
  transition: -webkit-transform 200ms;
  transition: transform 200ms;
  transition: transform 200ms, -webkit-transform 200ms;
}

.line-1 {
  -webkit-transform: translate3d(0, -8px, 0);
  transform: translate3d(0, -8px, 0);
}

.line-2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.line-3 {
  -webkit-transform: translate3d(0, 8px, 0);
  transform: translate3d(0, 8px, 0);
}

.menu-open:checked + .menu-open-button .line-1 {
  -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
  transform: translate3d(0, 0, 0) rotate(45deg);
}

.menu-open:checked + .menu-open-button .line-2 {
  -webkit-transform: translate3d(0, 0, 0) scale(0.1, 1);
  transform: translate3d(0, 0, 0) scale(0.1, 1);
}

.menu-open:checked + .menu-open-button .line-3 {
  -webkit-transform: translate3d(0, 0, 0) rotate(-45deg);
  transform: translate3d(0, 0, 0) rotate(-45deg);
}


/* .menu-item {
  transition: all 0.1s ease 0s;
} */

.menu-item:hover {
  background: #EEEEEE;
  color: #3290B1;
}

.menu-item:nth-child(3) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu-item:nth-child(4) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu-item:nth-child(5) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu-item:nth-child(6) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu-item:nth-child(7) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu-item:nth-child(8) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu-item:nth-child(9) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu-open-button {
  z-index: 2;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms;
  -webkit-transform: scale(1.1, 1.1) translate3d(0, 0, 0);
  transform: scale(1.1, 1.1) translate3d(0, 0, 0);
  cursor: pointer;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.07);
}

.menu-open-button:hover {
  -webkit-transform: scale(1.2, 1.2) translate3d(0, 0, 0);
  transform: scale(1.2, 1.2) translate3d(0, 0, 0);
}

.menu-open:checked + .menu-open-button {
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transform: scale(0.8, 0.8) translate3d(0, 0, 0);
  transform: scale(0.8, 0.8) translate3d(0, 0, 0);
}

.menu-open:checked ~ .menu-item {
  -webkit-transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
  transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
}

.menu-open:checked ~ .menu-item:nth-child(3) {
  transition-duration: 180ms;
  -webkit-transition-duration: 180ms;
  -webkit-transform: translate3d(0.08361px, -70.99997px, 0);
  transform: translate3d(0.08361px, -70.99997px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(4) {
  transition-duration: 280ms;
  -webkit-transition-duration: 280ms;
  -webkit-transform: translate3d(60.9466px, -52.47586px, 0);
  transform: translate3d(60.9466px, -52.47586px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(5) {
  transition-duration: 380ms;
  -webkit-transition-duration: 380ms;
  -webkit-transform: translate3d(60.9466px, 15.47586px, 0);
  transform: translate3d(60.9466px, 15.47586px, 0);
}

.credit {
  margin: 24px 20px 120px 0;
  color: #EEEEEE;
}

.credit a {
  padding: 8px 0;
  color: #C49CDE;
  text-decoration: none;
  transition: all 0.3s ease 0s;
}

.credit a:hover {
  text-decoration: underline;
}
