.Hero{
    height: 600px;
    gap: 20px;
    flex-direction: column;
    background-size: cover;
    background-position: 50%;
    position: relative;
}
.Hero h1,.Hero p,.Hero button{
    color: #fff;
}
.Hero h1{
font-size: 2.5rem;
}
.contactBtn{
cursor: pointer;
    }
.Cards{
    flex-direction: row;
}
@media screen and (max-width:700px) {
.Hero{
    height: 500px;
}
.Cards{
    flex-direction: column;
}
.Hero h1{
    width: 80%;
    font-size: 1.6rem;
}
}
@media screen and (min-width:701px)and (max-width:900px) {
    .Hero h1{
        width: 80%;
        font-size: 2.5rem;
    }
}