.avatar {
	width: 80px;
	height: 80px;
	box-sizing: border-box;
	border-radius: 50%;
	overflow: hidden;
	box-shadow: 0 2.5px 15px 0px rgba(0,0,0,0.25);
	transform: translatey(0px);
	animation: float 3.5s ease-in-out infinite;
    position: relative;
}
  
.ClientFavicon:nth-child(odd) .avatar {
	-webkit-animation: vibrate-1 2.5s linear infinite both;
	        animation: vibrate-1 2.5s linear infinite both;
}
.ClientFavicon:nth-child(even) .avatar {
	-webkit-animation: vibrate-2 2.5s linear infinite both;
	animation: vibrate-2 2.5s linear infinite both;
}
.avatar img { width: 100%; height: auto; object-fit: cover;}

.content {
	width: 100%;
	max-width: 600px;
	padding: 20px 40px;
	box-sizing: border-box;
	text-align: center;
}


/* ----------------------------------------------
 * Generated by Animista on 2022-7-9 15:36:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
 @-webkit-keyframes vibrate-1 {
	0% {
	  -webkit-transform: translate(0);
			  transform: translate(0);
	}
	20% {
	  -webkit-transform: translate(-3px, 3px);
			  transform: translate(-3px, 3px);
	}
	40% {
	  -webkit-transform: translate(-3px, -3px);
			  transform: translate(-3px, -3px);
	}
	60% {
	  -webkit-transform: translate(3px, 3px);
			  transform: translate(3px, 3px);
	}
	80% {
	  -webkit-transform: translate(3px, -3px);
			  transform: translate(3px, -3px);
	}
	100% {
	  -webkit-transform: translate(0);
			  transform: translate(0);
	}
  }
  @keyframes vibrate-1 {
	0% {
	  -webkit-transform: translate(0);
			  transform: translate(0);
	}
	20% {
	  -webkit-transform: translate(-3px, 3px);
			  transform: translate(-3px, 3px);
	}
	40% {
	  -webkit-transform: translate(-3px, -3px);
			  transform: translate(-3px, -3px);
	}
	60% {
	  -webkit-transform: translate(3px, 3px);
			  transform: translate(3px, 3px);
	}
	80% {
	  -webkit-transform: translate(3px, -3px);
			  transform: translate(3px, -3px);
	}
	100% {
	  -webkit-transform: translate(0);
			  transform: translate(0);
	}
  }
  
  /* ----------------------------------------------
 * Generated by Animista on 2022-7-9 15:38:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-2
 * ----------------------------------------
 */
@-webkit-keyframes vibrate-2 {
	0% {
	  -webkit-transform: translate(0);
			  transform: translate(0);
	}
	20% {
	  -webkit-transform: translate(3px, -3px);
			  transform: translate(3px, -3px);
	}
	40% {
	  -webkit-transform: translate(3px, 3px);
			  transform: translate(3px, 3px);
	}
	60% {
	  -webkit-transform: translate(-3px, 3px);
			  transform: translate(-3px, 3px);
	}
	80% {
	  -webkit-transform: translate(-3px, -3px);
			  transform: translate(-3px, -3px);
	}
	100% {
	  -webkit-transform: translate(0);
			  transform: translate(0);
	}
  }
  @keyframes vibrate-2 {
	0% {
	  -webkit-transform: translate(0);
			  transform: translate(0);
	}
	20% {
	  -webkit-transform: translate(3px, -3px);
			  transform: translate(3px, -3px);
	}
	40% {
	  -webkit-transform: translate(3px, 3px);
			  transform: translate(3px, 3px);
	}
	60% {
	  -webkit-transform: translate(-3px, 3px);
			  transform: translate(-3px, 3px);
	}
	80% {
	  -webkit-transform: translate(-3px, -3px);
			  transform: translate(-3px, -3px);
	}
	100% {
	  -webkit-transform: translate(0);
			  transform: translate(0);
	}
  }
  /* ----------------------------------------------
 * Generated by Animista on 2022-7-9 15:36:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
@-webkit-keyframes vibrate-1 {
	0% {
	  -webkit-transform: translate(0);
			  transform: translate(0);
	}
	20% {
	  -webkit-transform: translate(-3px, 3px);
			  transform: translate(-3px, 3px);
	}
	40% {
	  -webkit-transform: translate(-3px, -3px);
			  transform: translate(-3px, -3px);
	}
	60% {
	  -webkit-transform: translate(3px, 3px);
			  transform: translate(3px, 3px);
	}
	80% {
	  -webkit-transform: translate(3px, -3px);
			  transform: translate(3px, -3px);
	}
	100% {
	  -webkit-transform: translate(0);
			  transform: translate(0);
	}
  }
  @keyframes vibrate-1 {
	0% {
	  -webkit-transform: translate(0);
			  transform: translate(0);
	}
	20% {
	  -webkit-transform: translate(-3px, 3px);
			  transform: translate(-3px, 3px);
	}
	40% {
	  -webkit-transform: translate(-3px, -3px);
			  transform: translate(-3px, -3px);
	}
	60% {
	  -webkit-transform: translate(3px, 3px);
			  transform: translate(3px, 3px);
	}
	80% {
	  -webkit-transform: translate(3px, -3px);
			  transform: translate(3px, -3px);
	}
	100% {
	  -webkit-transform: translate(0);
			  transform: translate(0);
	}
  }