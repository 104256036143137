.LngSelect{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: absolute;
    background-color: rgba(28, 10, 2, 0.876);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.LngSelect .row{
    display: flex;
    flex-wrap: wrap;
}
.LngSelect img{
    width: 125px;
    position: absolute;
    top: 80px;
}
.LngSelect .social-icons
{
    position: absolute;
    bottom: 80px;
}
.LngSelect .social-icons a
{
    background-color: var(--orange) !important;
    color: white;
}
.LngSelect a {
    text-decoration: none;
    color: #fff;
}
.arabic,.hebrew,.english{
    z-index: 10;
background-color: var(--orange);
width: 100px;
height: 100px;
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
margin: 20px;
font-size: 1.1rem;
}

.arabic:hover,.hebrew:hover,
.arabic:active,.hebrew:active{
    box-shadow: inset 2px 5px 10px 8px rgba(0,0,0,0.23);
}