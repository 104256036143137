.WwdCard{
    position: relative;
    height: 270px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    background-color: ORANGE;
}
.WwdCard h1,.WwdCard h4,.icon{
margin: 10px 0px;
z-index: 2;
}