.favicons {
    width: 100vw;
    position: relative;
    /* background-color: red; */
  }
  
  .ClientFavicon {
    position: absolute;
    transform: translateY(-30%);
  }
  
  
  
.Clients{
flex-direction: column;
overflow-x: hidden;
}
.clients-slider img{
    width: 80%;
}
.clients-slider{
    width: 100vw;
    height: 230px;
    display: flex;
    justify-content: center;
    gap: 50px;
    align-items: center;
}

@media screen and (min-width: 900px) {
    .clients-slider img{
        width: 400px;
    }
    .clients-slider{
        height: 300px;
        margin-top: 50px;
    }
    .favicons{
        display: flex;
        flex-direction: row;
    }
    .favicons .ClientFavicon{
        /* margin: 150px 0px; */

    }
    .favicons .ClientFavicon:nth-child(even){
        transform: translateY(50px);
    }
}
.websites-type{
display: flex;
justify-content: center;
align-items: center;
width: 100%;
gap: 10px;
height: 50px;
}
.websites-type button {
    border: none;
    height: 35px;
    
    /* width: 100px; */
    border-radius: 17px;
    opacity: 0.7; /* Initial opacity */
    color: rgba(255, 255, 255, 0.724);
    padding: 5px 15px;
    background-color: var(--orange);
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  /* Style for the active button */
  .websites-type button.active {
    opacity: 1;
  }